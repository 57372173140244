import React, { useState } from 'react';
// import Config from "config"
import styled, { keyframes } from "styled-components"
// import moment from "moment";
// import { useCookies } from 'react-cookie';
import { ConfigProvider } from 'antd';
import mnMN from 'antd/lib/locale/mn_MN';
import 'moment/locale/mn';
import swDev from './swDev';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from 'react-dom';
// let deferredPrompt;
let PayrollApp = React.lazy(() => import("emp/PayrollApp"));
// let App = React.lazy(() => import("./App"));

var date = new Date();
date.setTime(date.getTime() + 2000*24*60*60*1000);

const Globals = () => {
  // const [ cookie, setCookie ] = useCookies();
  // const [ cName, setCName ] = useState(false);
  // const [ showPwa, setShowPwa ] = useState(false);

  // const CloseHandle = () =>{
  //     setCName(true);
  //     setTimeout(() => {
  //       setCookie('pwa', 'wait', { expires: new Date(moment().add(60 * 24, 'm').format()) });
  //       setShowPwa(false);
  //     }, 290)
  // }
  
  // window.addEventListener('beforeinstallprompt', (e) => {
  //     deferredPrompt = e;
  //     setShowPwa(true);
  // });

  // function installApp(){
  //   if(deferredPrompt){
  //     deferredPrompt.prompt();
  //       deferredPrompt.userChoice.then(res=>{
  //         if (res.outcome === 'accepted') {
  //             setShowPwa(false)
  //         } else if (res.outcome === 'dismissed') {
  //             console.log('User dismissed the install prompt');
  //         }
  //     });
  //     deferredPrompt = null;
  //   }
  // }

  return (
    <ConfigProvider locale={mnMN}>
       {/* { showPwa && cookie.pwa !== "wait" && <PWA cName={cName}>
          <div className="content">
              <div className="header"><div className="title">Татаж суулгах</div></div>
              <div className="content_body">
                  <button onClick={CloseHandle} className="custom custom2" >Дараа болы </button>
                  <button onClick={_=>installApp()} className="custom" >Суулгах  </button>
              </div>
          </div>
       </PWA> } */}
      <Routes>
        {/* {ImportType()} */}
        {/* <Route path="/" element={<React.Suspense fallback={<></>}><App /></React.Suspense>} /> */}
        <Route path="/" element={<React.Suspense fallback={<></>}><PayrollApp /></React.Suspense>} />
      </Routes>
    </ConfigProvider>

  )
}

export const smooth = keyframes`
    0%{ transform: scale(0.96); margin-top:-13rem; opacity:0; }
    100%{ transform: scale(1); margin-top: -10rem; opacity:1; }
`
export const smooth2 = keyframes`
    0%{ transform: scale(1); margin-top: -10rem; opacity:1; }
    100%{ transform: scale(0.98); margin-top: -13rem; opacity:0; }
`

const PWA = styled.div`
    font-family:roboto;
    z-index:1000;
    display:flex;
    align-items:center;
    justify-content:center;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.4);
    padding:0px 18px;
    .content{
        animation: ${props=>props.cName?smooth2:smooth} 0.4s ease;
        background-color:#fff;
        border-radius:8px;
        margin-top:-10rem;
        .header{
            padding:20px 20px;
            .title{
                font-size:19px;
            }
        }
        .content_body{
            padding:30px 20px;
            display:flex;
            gap:20px;
            justify-content:end;
            .custom{
                font-weight:500;
                width:160px;
                font-size:13px;
                outline: none;
                border: none;
                background-color: #0d6efd;
                color: #fff;
                padding: 6px 10px;
                letter-spacing: 0.1px;
                border-radius: 4px;
                &:hover{
                  opacity:0.8;
                }
            }
            .custom2{
              border:1px solid #0d6efd;
              background-color:#fff;
              color:rgba(0,0,0,0.8);
            }
        }
    }
    @media (max-width:790px){
        .content{
            .content_body{
                flex-direction:column;
            }
        }
    }
`

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
        <Globals />
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// serviceWorkerRegistration.register();
// reportWebVitals();

swDev()
